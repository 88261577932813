import { FC, useContext, useEffect, useState } from "react";
import { Toolbar, IconButton, Typography } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { RootState } from "store";
import { useLocation } from "react-router-dom";
import { context } from "providers";
import { Roles } from "types";
import { useAppDispatch, useAppSelector } from "utils/redux.utils";
import { open } from "store/drawer";


const AppbarBody: FC = () => {
    const dispatch = useAppDispatch()
    const { isOpen } = useAppSelector((state: RootState) => state.layout.drawer)
    const location = useLocation()
    const { links } = useContext(context.links);
    const [name, setName] = useState<string | undefined>("");

    useEffect(() => {
        console.log(location.pathname);

        const element = links.find(link => link.fullPath === location.pathname && !link.permissions.includes(Roles.GUEST))
        setName(element?.name)
    }, [links, location.pathname])

    return <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(open())}
            edge="start"
            sx={{ mr: 2, ...(isOpen && { display: 'none' }) }}
        >
            <Menu />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
            {name}
        </Typography>
    </Toolbar>
}

export default AppbarBody;