import { ChevronLeft, ChevronRight, Inbox, Logout, Mail } from "@mui/icons-material"
import { Drawer, IconButton, Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { FC, useContext } from "react"
import { useTheme } from '@mui/material/styles';
import { DRAWER_WIDTH } from "utils/constants.utils"
import { RootState } from "store";
import DrawerHeader from "./drawer.header"
import { useAppDispatch, useAppSelector } from "utils/redux.utils";
import { close } from "store/drawer";
import { context } from "providers";
import { Link, useNavigate } from "react-router-dom";
import { Roles } from "types";

const DrawerContainer: FC = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch()
    const { isOpen } = useAppSelector((state: RootState) => state.layout.drawer)
    const { links } = useContext(context.links)
    const navigate = useNavigate()
    const { logoutWithRedirect } = useContext(context.auth);


    const onClick = async () => {
        await logoutWithRedirect!(navigate);
    }

    return <Drawer
        sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: DRAWER_WIDTH,
                boxSizing: 'border-box',
            },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
    >
        <DrawerHeader>
            <IconButton onClick={() => dispatch(close())}>
                {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            {links.filter(link => link.exact && link.permissions.includes(Roles.ADMIN)).map((link, index) => (
                <ListItem button key={link.fullPath} to={link.fullPath} component={Link}>
                    <ListItemIcon>
                        {index % 2 === 0 ? <Inbox /> : <Mail />}
                    </ListItemIcon>
                    <ListItemText primary={link.name} />
                </ListItem>
            ))}
        </List>
        <Divider />
        <List>
            <ListItem button key="Log out" onClick={onClick}>
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText primary="Log out" />
            </ListItem>
        </List>
    </Drawer>
}

export default DrawerContainer