import { createContext, FC, useEffect, useState } from "react";
import { getAuth, User, onAuthStateChanged, signOut, signInWithEmailAndPassword } from "firebase/auth";
// import { toast } from "react-toastify";
import { AuthContext as IAuthContext } from "types/auth.types";
import { Roles } from "types";
import { NavigateFunction } from "react-router-dom";
import { axios } from "utils/fetch.utils";

export const AuthContext = createContext<IAuthContext>({
  isLoggedIn: false,
  loading: true,
  user: null,
  roles: [],
});

const AuthProvider: FC = (props) => {
  const [user, setUser] = useState<null | User>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(!!user);
  const [roles, setRoles] = useState<Roles[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const onAuthStateChange = () => {
      return onAuthStateChanged(getAuth(), async (user) => {
        const tokenResult = await getAuth().currentUser?.getIdTokenResult();
        const tokenId = await getAuth().currentUser?.getIdToken(true);
        const userRoles = tokenResult?.claims.roles as Roles[];
        if (userRoles && tokenId) {
          setUser(user);
          setIsLoggedIn(!!user);
          setRoles([...userRoles, "GUEST"] as Roles[]);
          axios.defaults.headers.common = { 'Authorization': tokenId };
        } else {
          // logout!();
          setUser(null);
          setIsLoggedIn(false);
          setRoles([]);
        }
        setLoading(false);
      });
    };
    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, []);

  const logout = async () => {
    try {
      setIsLoggedIn(false);
      await signOut(getAuth());
      setUser(null);
      setRoles([]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const logoutWithRedirect = async (navigate: NavigateFunction) => {
    try {
      setLoading(true);
      navigate("/")
      await logout();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(getAuth(), email, password)
      setLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        // toast.error(error.message);
      } else {
        console.log(error)
      }
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        roles,
        loading,
        logout,
        login,
        logoutWithRedirect,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
