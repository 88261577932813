import { FC, lazy, useContext } from "react";
import { Route, Routes } from "react-router";
import { context } from "providers";
import { getAllowedRoutes } from "utils/routes.utils";
import { Roles } from "types";
import { Auth, App } from "components/layouts";
import Loading from "components/utils/loading";
import NotFoundPage from "pages/404";

const AppRoutes: FC = () => {
  const { roles, isLoggedIn, loading: LoadingAuth } = useContext(context.auth);
  const { links, loading: LoadingLinks } = useContext(context.links);

  if (LoadingLinks) {
    return <Loading />
  }

  if (LoadingAuth) {
    return <Loading />
  }

  return (
    <Routes>
      <Route path="/" element={isLoggedIn ? <App /> : <Auth />}>
        {getAllowedRoutes([...roles, Roles.GUEST] as Roles[], links).map(
          (route) => {
            if (route.layout) {
              const Layout = lazy(
                () => import(`../pages${route.layout}`)
              )
              const Page = lazy(
                () => import(`../components${route.layout}${route.component}`)
              )
              return <Route key={`${route.layout}${route.component}`} path={route.layout} element={<Layout />}>
                <Route key={route.component} path={route.path} element={<Page />} />
              </Route>
            } else {
              const Page = lazy(
                () => import(`pages/${route.component}`)
              )
              return <Route key={route.path} path={route.path} element={
                <Page />
              } />
            }

          }
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
