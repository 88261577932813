import { createSlice } from "@reduxjs/toolkit";

const name = "layout/drawer"

export type DrawerState = {
    isOpen: boolean
}

const initialState: DrawerState = {
    isOpen: false
}

export const drawerSlice = createSlice({
    name,
    initialState,
    reducers: {
        close(state) {
            state.isOpen = false
        },
        open(state) {
            state.isOpen = true
        }
    }
})

export const { close, open } = drawerSlice.actions

export default drawerSlice.reducer