import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";

import drawer from "./drawer"
import cards from "./cards";
import transactions from "./transactions"

const layout = combineReducers({
    drawer
})


export const store = configureStore({
    reducer: {
        layout,
        cards,
        transactions
    }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>