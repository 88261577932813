import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import { Response, Transaction } from "types"
import { axios } from "utils/fetch.utils"

const name = "dashboard/transitions"

export type TransactionState = {
    transitions: Transaction[]
    isLoading: boolean
    errors: SerializedError[]
}

export enum Actions {
    FETCH_TRANSACTIONS = "dashboard/transactions/fetchTransactions/pending"
}

const initialState: TransactionState = {
    errors: [],
    transitions: [],
    isLoading: false
}

export const fetchTransactions = createAsyncThunk(
    Actions.FETCH_TRANSACTIONS,
    async () => {
        return (await axios.get<any, AxiosResponse<Response<Transaction[]>>>(`/transactions`)).data
    }
)

export const transactionSlice = createSlice({
    name,
    initialState,
    reducers: {
        clear() { }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.transitions = action.payload.data
                state.isLoading = false
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                state.errors = [...state.errors, {
                    ...action.error
                }]
            })
    }
})

export const { clear } = transactionSlice.actions

export default transactionSlice.reducer