import { FC } from "react"
import { Outlet } from "react-router"
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { DrawerContainer, DrawerHeader } from "./drawer"
import { Appbar, AppbarBody } from "./appbar";
import { useAppSelector } from "utils/redux.utils";
import { RootState } from "store";
import { DRAWER_WIDTH } from "utils/constants.utils";


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Layout: FC = () => {
  const { isOpen } = useAppSelector((state: RootState) => state.layout.drawer)

  return (
    <Box sx={{ display: 'flex' }}>
      <Appbar position="fixed" open={isOpen}>
        <AppbarBody />
      </Appbar>
      <DrawerContainer />
      <Main open={isOpen}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}

export default Layout