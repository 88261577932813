import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { Card, UpsertCard, Response } from "types";
import { axios } from "utils/fetch.utils"

const name = "cards"

export type CardsState = {
    card: Card | null;
    cards: Card[];
    isLoading: boolean
    errors: SerializedError[]
}

export enum Actions {
    FETCH_CARDS = "cards/fetchCards/pending",
    UPSERT_CARD = "cards/upsertCard/pending",
    FETCH_CARD_BY_ID = "cards/fetchCardById/pending",
    DELETE_CARD_BY_ID = "cards/deleteCardById/pending",
}

const initialState: CardsState = {
    card: null,
    cards: [],
    isLoading: false,
    errors: []
}

export const fetchCards = createAsyncThunk(
    Actions.FETCH_CARDS,
    async () => {
        return (await axios.get<any, AxiosResponse<Response<Card[]>>>("/cards")).data
    }
)

export const upsertCard = createAsyncThunk(
    Actions.UPSERT_CARD,
    async (data: UpsertCard) => {
        return (await axios.post<any, AxiosResponse<Response<Card>>, UpsertCard>("/cards", data)).data
    }
)

export const fetchCardById = createAsyncThunk(
    Actions.FETCH_CARD_BY_ID,
    async (id: string) => {
        return (await axios.get<any, AxiosResponse<Response<Card>>>(`/cards/${id}`)).data
    }
)

export const deleteCardById = createAsyncThunk(
    Actions.DELETE_CARD_BY_ID,
    async (id: string) => {
        const r = (await axios.delete<any, AxiosResponse<Response<Card>>>(`/cards/${id}`))
        return r.data
    }
)

export const cardsSlice = createSlice({
    name,
    initialState,
    reducers: {
        clear() { }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCards.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchCards.fulfilled, (state, action) => {
                state.cards = action.payload.data
                state.isLoading = false
            })
            .addCase(fetchCards.rejected, (state, action) => {
                state.errors = [...state.errors, {
                    ...action.error,
                }]
            })
            .addCase(fetchCardById.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchCardById.fulfilled, (state, action) => {
                state.card = action.payload.data
                state.isLoading = false
            })
            .addCase(fetchCardById.rejected, (state, action) => {
                state.errors = [...state.errors, {
                    ...action.error,
                }]
            })
    }
})



export const { clear } = cardsSlice.actions

export default cardsSlice.reducer